import React, {useState, useEffect, Component} from "react";
import { Line } from '@ant-design/charts';

const WeatherContext = React.createContext({
    readings: [], fetchReadings: () => {}
})

export default function Weather() {
    const [readings, setReadings] = useState([])
    const fetchReadings = async () => {
        // const readings = []
        const readings = await (await fetch('https://2190weather.fly.dev/graph')).json()
        console.log(readings)
        setReadings(readings)
    }

    // https://charts.ant.design/demos/line#%E4%BA%8C%E6%B0%A7%E5%8C%96%E7%A2%B3%E6%8E%92%E6%94%BE%E9%87%8F%E6%9D%A5%E6%BA%90

    const config = {
        data: readings,
        height: 400,
        xField: 'time',
        yField: 'value',
        seriesField: 'category',
        xAxis: { type: 'time' },
        yAxis: {
          label: {
            formatter: function formatter(v) {
              return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                return ''.concat(s, ',');
              });
            },
          },
        },
        legend: { position: 'top' },
        smooth: true,
        animation: {
          appear: {
            animation: 'path-in',
            duration: 1250,
          },
        },
      };

    useEffect(() => {
        fetchReadings()
    }, [])
    return (
        <WeatherContext.Provider value={{readings, fetchReadings}}>
            <Line {...config} />
                {/*{readings.map((data, time) => (*/}
                {/*        <li key={data.time}>*/}
                {/*            {data}*/}
                {/*        </li>*/}
                {/*    ))}*/}
        </WeatherContext.Provider>
    )
};